import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function CarouselComponent() {
    return (
        
        <div class="carousel-wrapper" >
            <Carousel infiniteLoop useKeyboardArrows autoPlay >
                <div>
                    <img src="../tumpalanfam.jpg" alt="logo" />
                </div>
                <div>
                    <img src="../fvp_banner1.jpg" alt="logo" />
                </div>
                <div>
                    <img src="../fvp_banner.jpg" alt="logo" />
                </div>
            </Carousel>
        </div>
    );
}