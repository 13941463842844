import React from 'react';
import { useState } from "react";
//import SweetAlert2 from 'react-sweetalert2';
//import Loader from "./loader";
 import './App.css';
import CarouselComponent from "./carousel.component";
//import LazyLoad from 'react-lazy-load';

import { LazyLoadImage } from 'react-lazy-load-image-component';

//import vitalogo from './image/vitalogo.jpg';
import beadealer from './assets/1. BE A DEALER.png';
import events from './assets/2. EVENTS.png';
import myvita from './assets/3. MY VITA.png';
//import buyproducts from './assets/4. BUY PRODUCTS.png';
//import news from './assets/5. NEWS.png';
import inquiries from './assets/6. INQUIRIES.png';
import schedules from './assets/7. SCHEDULES.png';
import website from './assets/8. WEBSITE.png';
import storedirectory from './assets/10. STORE DIRECTORY.png';
import raffleticket from './assets/11. RAFFLE TICKET.png';
import connectwithus from './assets/12. CONNECT WITH US.png';
import promo from './assets/13. PROMO.png';
import weeklyincentive from './assets/15. WEEKLY INCENTIVE.png';
import requestgenealogy from './assets/16. REQUEST GENEALOGY.png';
import catalog from './assets/17. CATALOG.png';
//import design from './assets/line.png';
//import fb from './assets/fb.png';
//import tiktok from './assets/tiktok.png';
//import twitter from './assets/twitter.png';
//import insta from './assets/insta.png';
//import Footer from './components/Footer.js';


//import Swal from 'sweetalert2';
//import { Image } from 'react-bootstrap';


function App() { 


  // const Alertredir= () => {
  //    Swal.fire({
  //      title: "Are you sure?",
  //      text: "You will be redirected",
  //      icon: "warning",
  //      showCancelButton: true,
  //      cancelButtonColor: "#d33",
  //      confirmButtonText: "Okay"
  //    }).then((result) => {
  //      // Redirect the user
  //      if (result.isConfirmed){
  //      window.location.href = "https://content.fvp.ph/anniversary/annivcat.php";
  //      } else if (result.showCancelButton) {

  //      }  
  //     });

  // };

  // const Alert = () => {
  //   Swal.fire({
  //     title: 'Coming Soon!',
  //     // text: 'Coming Soon!',
  //     // icon: 'message',
  //     confirmButtonText: 'Close'
  //   })
  // };

  const [isLoading, setIsLoading] = useState(true);
 
    setTimeout(() => {
        setIsLoading(false);
    }, 1000 );

  return (
     <div style={{textAlign: "center", margin: "auto", height:"auto"}}>
            
            {isLoading ? (
                  <div style={{width: "100px", margin: "auto" }}>
                  
                  {/* <Loader /> */}
{/* 
                  <h1 style={{textAlign: "center", color: "green", margin: "auto", fontWeight: "bold", fontStyle: 'italic'}}>
                    Loading...
                  </h1> */}

                  </div>
              ) : (
  <div>
      <div className="App" >
        
         {/* <div className='App-new-image' > 
         <img
         src={vitalogo} className="App-new-image" alt="logo"
        />
        </div>  */}
          <div className="App-head" >
          
          </div>
  

        <div className="App"> 
          <CarouselComponent />
        </div> 


    <div className="App">
     

            <header>

             
             <div className="App-header">
            
              <a 
                  href="https://content.fvp.ph/dealership/dealership.php"
               >
              <LazyLoadImage
              src={beadealer} className="App-logo" alt="logo" 
            //  width={300} height={300} 
              effect="blur"/>
              </a>

              <a 
               href="https://content.fvp.ph/anniversary/annivcat.php"
              // onClick={Alertredir} 
              >
              <LazyLoadImage 
               src={events} className="App-logo" alt="logo"
             //  width={300} height={300}
               effect="blur"/>
              </a>

              </div>


             <div className="App-header">
              <a 
                href="https://fvpph.com/" 
              >
              <LazyLoadImage
              src={myvita} className="App-logo" alt="logo" 
            //  width={300} height={300} 
              effect="blur"/>
              </a>

              <a
               href="https://fvpph.com/App/">
              <LazyLoadImage src={inquiries} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>
              </a> 

               {/* <a 
                onClick={Alert}
              // href="https://firstvitaplus.net" target="_blank" rel="noopener noreferrer"
              >
              <LazyLoadImage 
               src={buyproducts} className="App-logo" alt="logo"
             //  width={300} height={300}
               effect="blur"/>
              </a> */}
              </div>

             <div className="App-header">
              {/* <a
                 href="https://content.fvp.ph/news/news.php" target="_blank" rel="noopener noreferrer"
                //onClick={Alert}
                >
              <LazyLoadImage src={news} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>    
              </a> */}

              {/* <a
               href="https://fvpph.com/App/" target="_blank" rel="noopener noreferrer">
              <LazyLoadImage src={inquiries} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>
              </a>  */}
              </div>


             <div className="App-header">
              <a
                href="https://content.fvp.ph/semsched/sem.php">
           
                <LazyLoadImage src={schedules} className="App-logo" alt="logo" 
            //   width={300} height={300}
               effect="blur"/>
               
              </a>

               <a
                href="https://firstvitaplus.net">
           
                <LazyLoadImage src={website} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>
               
              </a> 
              </div>
            

             <div className="App-header">
              <a
                href="https://content.fvp.ph/contactus/">
           
              <LazyLoadImage src={storedirectory} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>
               
              </a>

               <a
                href="https://fvpph.com/App">
           
               <LazyLoadImage src={raffleticket} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>
               
              </a> 
              </div>



             <div>
              <a
                href="https://content.fvp.ph/connectus/connectus.php">
           
               <LazyLoadImage src={connectwithus} className="App-logo" alt="logo" 
            //   width={300} height={300}
               effect="blur"/>
               
              </a>

               <a
                href="https://content.fvp.ph/promo/promo.php">
           
               <LazyLoadImage src={promo} className="App-logo" alt="logo" 
              // width={300} height={300}
               effect="blur"/>
               
              </a>  
              </div>


             <div>
              <a
               href="https://fvpph.com/App" >
               <LazyLoadImage src={weeklyincentive} className="App-logo" alt="logo" 
              // width={300} height={300}
               effect="blur"/>   
              </a>

              <a
              href="https://fvpph.com/App">
              <LazyLoadImage src={requestgenealogy} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>          
              </a>  
              </div>


             <div>
              <a
                href="https://content.fvp.ph/catalog/catalog2.php">
           
               <LazyLoadImage src={catalog} className="App-logo" alt="logo" 
             //  width={300} height={300}
               effect="blur"/>

              </a>

            </div>  

            <div>
    

            </div>  
           
           

          </header>
        
        </div>

   </div>
 </div>
 )}

             {/* <div className="App-footer"
              
             >
                   <a
                    href="https://facebook.com/FVPMCOfficial/" target="_blank" rel="noopener noreferrer">
                    <img src={fb} className="App-image" alt=""/>
                   </a>

                   <a
                    href="https://www.facebook.com/FVPMCOfficial/" target="_blank" rel="noopener noreferrer">
                    <img src={tiktok} className="App-image" alt=""/>
                   </a>

                    <a
                    href="https://www.instagram.com/fvpph/?hl=en" target="_blank" rel="noopener noreferrer">
                    <img src={insta} className="App-image" alt=""/>
                   </a>

                    <a
                    href="https://twitter.com/fvpph?lang=en" target="_blank" rel="noopener noreferrer">
               
                    <img src={twitter} className="App-image" alt=""/>
                   </a>

              </div>  
           */}

           <div className="App-footer">
           </div>
             
</div> 
  );

}



export default App;

 // const styles= StyleSheet.create({
 //     container: {
 //       flex: 1,
 //       backgroundColor: '#ecf0f1',
 //     },
 //     content: {
 //       flex: 1
 //     },
 //   footer: {
 //      backgroundColor: "blue",
 //    padding: 40
 //   }
 //  });
